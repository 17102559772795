.inputFills {
	display: block;
	margin: auto !important;
	width: 100% !important;
}

/* Modify React Material-UI styling */
.MuiInputBase-root {
	background-color: white;
}

.MuiFormControl-marginNormal {
	margin: 0px !important;
}
