.logo {
	-webkit-transform: translateZ(0);
	aspect-ratio: 1 / 1;
	border: 0;
	transform: translateZ(0);
	transition: all 0.25s ease-in-out;
}

.logo:hover {
	-moz-transform: perspective(1px) scale(1.1);
	-webkit-transform: perspective(1px) scale(1.1);
	transform: perspective(1px) scale(1.1);
}

.navbar {
	border: 1px solid transparent;
	box-shadow: none !important;
	margin-left: auto;
	margin-right: auto;
	min-height: 50px;
	padding-left: 15px;
	padding-right: 15px;
	position: relative;
}

.navbar-default {
	background-color: #f8f8f8 !important;
	border-color: #e7e7e7;
	color: #333333 !important;
}

.navbar-brand {
	color: #333333 !important;
	cursor: pointer;
	font-size: 0.9em !important;
	text-transform: none !important;
	transition: all 0.25s ease-in-out;
}

.navbar-brand:hover {
	background-color: transparent !important;
	color: #333 !important;
}

.navbar-header {
	font-size: 1.2em !important;
	margin: auto;
}

@media screen and (max-width: 750px) {
	.navbar-header {
		font-size: 1em !important;
	}
}

.navbar-toolbar {
	padding: 0.5% !important;
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.navbar-menu {
	font-size: 1.2em !important;
	margin-top: 20% !important;
}

/* Material-UI */
.navbar-dropdown {
	max-height: 70vh;
	max-width: 45vh;
}

.navbar-dropdown .MuiMenuItem-root {
	letter-spacing: initial !important;
	padding-bottom: 0 !important;
	padding-left: 2% !important;
	padding-right: 2% !important;
	padding-top: 0 !important;
}

.navbar-accordion-open {
	background-color: #f5f5f5 !important;
	transition: all 0.25s ease-in-out;
}

.navbar-accordion-open .Mui-expanded {
	background-color: #f5f5f5 !important;
	transition: all 0.25s ease-in-out;
}

.navbar-dropdown .MuiAccordion-root {
	box-shadow: none !important;
	width: 100% !important;
}

.navbar-dropdown .MuiAccordionSummary-root {
	min-height: initial !important;
	padding: initial !important;
}

.navbar-dropdown .MuiAccordionSummary-root:hover {
	background-color: #f5f5f5 !important;
}

.navbar-dropdown .MuiAccordionSummary-content {
	margin: 0 !important;
}

.navbar-dropdown .MuiAccordion-region {
	background-color: #fff;
}

.navbar-toolbar .MuiGrid-item {
	margin: auto;
}

.MuiMenu-paper {
	-webkit-box-shadow:
		0px 10px 13px -7px #000000,
		0px 1px 24px -3px rgba(0, 0, 0, 0.27);
	box-shadow:
		0px 10px 13px -7px #000000,
		0px 1px 24px -3px rgba(0, 0, 0, 0.27) !important;
}

/* Modal */
.dialog-content {
	margin: auto;
	width: 85% !important;
}
