.img-circle {
	height: 130px !important;
	margin: auto;
	padding-top: 2%;
	transition: all 0.5s;
	transition: all 0.5s;
	width: 130px !important;
}

.img-circle:hover {
	height: 150px !important;
	width: 150px !important;
}

.marketing-cards {
	box-shadow: none !important;
	margin: auto !important;
}

.marketing-cards h2 {
	font-size: 30px;
	font-weight: normal;
}

/* Rotate animation */
.animated {
	-webkit-animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-duration: 1s;
	animation-fill-mode: both;
}

@-webkit-keyframes rotateIn {
	0% {
		-webkit-transform-origin: center;
		-webkit-transform: rotate3d(0, 0, 1, -200deg);
		opacity: 0;
		transform-origin: center;
		transform: rotate3d(0, 0, 1, -200deg);
	}

	100% {
		-webkit-transform-origin: center;
		-webkit-transform: none;
		opacity: 1;
		transform-origin: center;
		transform: none;
	}
}

@keyframes rotateIn {
	0% {
		-webkit-transform-origin: center;
		-webkit-transform: rotate3d(0, 0, 1, -200deg);
		opacity: 0;
		transform-origin: center;
		transform: rotate3d(0, 0, 1, -200deg);
	}

	100% {
		-webkit-transform-origin: center;
		-webkit-transform: none;
		opacity: 1;
		transform-origin: center;
		transform: none;
	}
}

.rotateIn {
	-webkit-animation-name: rotateIn;
	animation-name: rotateIn;
}

/* Material-UI */
.MuiCardContent-root {
	padding-top: 0;
}

.MuiCardContent-root p {
	margin-top: 0% !important;
}

.MuiCardContent-root h2 {
	margin-bottom: 2% !important;
}
