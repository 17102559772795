.bar-carousel {
	height: 250px;
	margin-bottom: 60px;
}

.bar-carousel h1 {
	margin: 0;
}

.carousel-item {
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
}

.carousel-item-text {
	margin: auto;
	max-width: 80%;
	text-align: center;
}

.carousel-item-text h1 {
	color: white !important;
	font-family: inherit;
	font-size: 36px;
	font-weight: 500;
	line-height: 1.1;
	text-align: center;
}

.carousel-item-text p {
	color: white !important;
	font-size: 21px;
	line-height: 1.4;
	text-align: center;
}

.carousel-item-text h1,
.carousel-item-text p {
	/* Taken from https://css-tricks.com/adding-stroke-to-web-text/ */
	text-shadow:
		-1px -1px 0 #000,
		1px -1px 0 #000,
		-1px 1px 0 #000,
		1px 1px 0 #000;
}

.carousel-item-text-dark h1,
.carousel-item-text-dark p {
	color: rgb(0, 0, 0) !important;
	/* Taken from https://css-tricks.com/adding-stroke-to-web-text/ */
	text-shadow:
		-1px -1px 0 rgb(255, 255, 255),
		1px -1px 0 rgb(255, 255, 255),
		-1px 1px 0 rgb(255, 255, 255),
		1px 1px 0 rgb(255, 255, 255);
}

@media screen and (max-width: 750px) {
	.carousel-item-text h1 {
		font-size: 33px;
	}

	.carousel-item-text p {
		font-size: 18px;
		margin: 2% !important;
	}
}

.carousel-button {
	padding: 0.5% !important;
}

/* Material-UI */
.bar-carousel .MuiIconButton-root {
	background-color: inherit !important;
	bottom: 0;
	font-size: 20px;
	left: 0;
	opacity: 0.9;
	text-align: center;
	top: 0;
}

.bar-carousel .MuiIconButton-root:hover {
	background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0, rgba(0, 0, 0, 0.0001) 100%);
	background-repeat: repeat-x;
	color: #fff !important;
	opacity: 0.9;
	outline: 0;
	text-decoration: none;
}

.bar-carousel .MuiSvgIcon-root {
	fill: rgb(255, 255, 255) !important;
	filter: drop-shadow(0px 3px 5px rgb(0, 0, 0));
	font-size: 2.5em;
}
