.getIt {
	border-color: #000 !important;
	color: #000 !important;
	float: right;
	margin-left: 20px;
}

.getIt:hover {
	background-color: #e6e6e6;
	border-color: #adadad;
	color: #333;
}

.citation {
	padding-left: 22px;
	text-indent: -22px;
}
