.bar-apps {
	margin: 1%;
}

.apps-header {
	color: #383838;
	font-family: inherit !important;
	font-size: 3.1em;
	font-weight: normal;
	letter-spacing: -1px;
	line-height: 1;
	margin-bottom: 10px;
	margin-top: 0px !important;
	text-align: left;
}

.apps-description {
	color: #383838;
	font-family: inherit !important;
	font-size: 1.3em;
	font-weight: 300;
	line-height: 1.4;
	margin-bottom: 20px;
	margin: 0 0 10px;
	text-align: left;
}

.apps-category {
	color: #383838;
	font-family: inherit;
	font-size: 1.4em;
	font-weight: 500;
	line-height: 1.1;
	margin-bottom: 10px;
	margin-top: 20px;
	text-align: left;
}

.app-image {
	border-radius: 2px;
	border: 1px solid #cccccc;
	max-height: 120px;
	max-width: 100%;
	overflow: hidden;
	width: 180px;
	z-index: 2;
}

.app-button {
	-webkit-appearance: button !important;
	background-image: none;
	border-radius: 2px !important;
	border: 1px solid transparent !important;
	cursor: pointer !important;
	display: inline-block !important;
	font-family: inherit !important;
	font-size: 0.8em !important;
	font-weight: 400 !important;
	font: inherit;
	line-height: 1.4 !important;
	margin-bottom: 1% !important;
	margin-right: 2% !important;
	min-width: inherit !important;
	overflow: visible;
	padding: 4% 6%;
	text-align: center;
	text-transform: none !important;
	touch-action: manipulation;
	vertical-align: middle !important;
	white-space: nowrap;
}

.app-button:hover {
	text-decoration: none;
}

.app-button-white {
	background-color: #fff !important;
	border-color: #ccc !important;
	color: #333 !important;
}

.app-button-go {
	background-color: #a4db00 !important;
	border-color: #a4db00 !important;
	color: #000 !important;
}

.app-button-go:hover {
	background-color: #5cb85c !important;
	border-color: #5cb85c !important;
}

.app-card-header {
	padding-bottom: 4%;
}

.app-card-header .MuiTypography-root {
	font-weight: bold;
}

.app-card-content {
	padding: 5% !important;
}

/* Material-UI */
.bar-apps .MuiGrid-item {
	-webkit-transform: translateZ(0);
	border-radius: 2px;
	border: 1px solid #e3e3e3;
	box-shadow: 0px 10px 21px -16px rgb(0 0 0 / 75%);
	float: left;
	margin: 1%;
	max-width: 47vw;
	min-height: 20px;
	min-height: 235px;
	padding: 0px;
	position: relative;
	transform: translateZ(0);
	transition: all 0.25s ease-in-out;
	width: 200px;
	z-index: 0;
}

.bar-apps .MuiGrid-item:hover {
	background-color: #ececec !important;
	transform: scale(1.1);
	z-index: 1001;
}

.bar-apps .MuiPaper-root {
	background-color: #f5f5f5;
	height: 100%;
	margin: 0;
	width: 100%;
}

.bar-apps .MuiCardHeader-content .MuiTypography-root {
	color: #303030;
	font-size: 1.15rem !important;
	text-align: center;
	width: 100%;
	z-index: 3;
}

.bar-apps .MuiCardActionArea-root {
	height: 100%;
	width: 100%;
}

@media screen and (max-width: 750px) {
	.apps-header,
	.apps-description {
		text-align: center;
	}

	.bar-apps .MuiGrid-container {
		margin: auto 0.1px;
		max-width: 98vw;
	}

	.bar-apps .MuiGrid-item {
		margin-left: auto;
		margin-right: auto;
	}
}
