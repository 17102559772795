.news {
	border-bottom-color: rgb(153, 204, 0);
	border-bottom-style: solid;
	border-bottom-width: 4px;
	border-image-outset: initial;
	border-image-repeat: initial;
	border-image-slice: initial;
	border-image-source: initial;
	border-image-width: initial;
	border-left-color: rgb(153, 204, 0);
	border-left-style: solid;
	border-left-width: 4px;
	border-radius: 2px;
	border-right-color: rgb(153, 204, 0);
	border-right-style: solid;
	border-right-width: 4px;
	border-top-color: rgb(153, 204, 0);
	border-top-style: solid;
	border-top-width: 4px;
	border: solid 4px #a4db00;
	color: #383838 !important;
	margin: 1%;
	padding: min(10%, 40px);
	text-align: initial;
}

.news-heading {
	font-size: clamp(1rem, 11vw, 50px);
	font-weight: normal;
	letter-spacing: -1px;
	line-height: 1;
	margin-bottom: 0px !important;
	margin-top: 0px !important;
}

.news-description {
	font-size: 21px;
	font-weight: 300;
	line-height: 1.4;
	margin-bottom: 20px;
}

.news-description a {
	word-wrap: break-word;
	overflow-wrap: break-word;
}

.news-images {
	width: 100%;
}

/* Material-UI */
.news .MuiPaper-root {
	background-color: #fff;
	border-radius: 4px;
	border: 1px solid #dddddd;
	box-shadow: 0px 10px 21px -16px rgb(0 0 0 / 75%);
	height: 100%;
	overflow-y: auto;
}

.news .MuiCardHeader-root {
	background-color: #f5f5f5;
	border-bottom: 1px solid transparent;
	border-color: #ddd !important;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
	color: #333;
	padding: 10px 15px;
}

.news .MuiTypography-h5 {
	color: inherit;
	font-family: inherit;
	font-size: 16px;
	font-weight: 500;
	line-height: 1.1;
	margin-bottom: 0;
	margin-top: 0;
}

.news .MuiCardContent-root {
	color: #383838 !important;
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-size: 14px;
	line-height: 1.42857143;
	padding: 15px;
}
