/* BAR core */
html {
	scroll-behavior: smooth;
}

body {
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

a:active,
a:focus,
input,
input:active,
input:focus {
	outline: 0;
	outline-style: none;
	outline-width: 0;
}

a:active,
a:focus,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
	border: none;
}

a {
	color: #0049d9;
	text-decoration: none;
}

/* React default */

.App {
	text-align: center;
}

.App hr {
	border-top: 1px solid #eee;
	border: 0;
	box-sizing: content-box;
	height: 0;
	margin: 50px 0;
}
